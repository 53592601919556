import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const userSlice = createSlice({
  name: "user",
  initialState: {
    users: [],
    user: {},
    refetch: false,
    admin: {},
  },
  reducers: {
    getAllUsersSuccess: (state, action) => {
      state.users = action.payload;
    },
    getUserDetailSuccess: (state, action) => {
      state.user = action.payload;
    },
    refetch: (state, action) => {
      state.refetch = action.payload;
    },
    getAdminDetailSuccess: (state, action) => {
      state.admin = action.payload;
    },
    isUserUpdate: (state, action) => {
      state.isUserUpdate = action.payload;
    },
  },
});

export const {
  getAllUsersSuccess,
  getUserDetailSuccess,
  getAdminDetailSuccess,
  refetch,
} = userSlice.actions;

export const getAllUsers = (setIsLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/user/list/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    dispatch(getAllUsersSuccess(data.data));
    setIsLoading(false);
  } catch (error) {
    console.log(error);
    setIsLoading(false);
  }
};

export const getUserDetail = (id, setLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/user/details/${id}/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    dispatch(getUserDetailSuccess(data.data));
    setLoading(false);
  } catch (error) {
    console.log(error);
    setLoading(false);
  }
};

export const getAdminDetail = (setLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/user/admin/profile/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    dispatch(getAdminDetailSuccess(data.data));
    setLoading(false);
  } catch (error) {
    console.log(error);
    setLoading(false);
  }
};

export const updateUserStatus = (id, setLoading) => async (dispatch) => {
  try {
    dispatch(refetch(false));
    const url = `${process.env.REACT_APP_API_BASE_URL}/user/change/status/${id}/v1`;
    const res = await axios.put(
      url,
      {},
      {
        headers: { Authorization: localStorage.getItem("token") },
      }
    );
    dispatch(refetch(true));
  } catch (error) {
    console.log(error);
    setLoading(false);
  }
};

export const makeUserApprove =
  (id, setLoading, setAlertModel) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/user/accept/${id}/v1`;
      const { data } = await axios.put(
        url,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch(refetch(true));
      setLoading(false);
      setAlertModel(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setAlertModel(false);
    }
  };

export const makeUserReject =
  (id, setLoading, setAlertModel) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/user/reject/${id}/v1`;
      const { data } = await axios.put(
        url,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch(refetch(true));
      setLoading(false);
      setAlertModel(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setAlertModel(false);
    }
  };

export const changeUserStatus =
  (id, setLoading, setModalShow) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/user/change/active/status/${id}/v1`;
      const { data } = await axios.put(
        url,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch(refetch(true));
      setLoading(false);
      setModalShow(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setModalShow(false);
    }
  };

export const changeAdminPassword =
  (formData, setLoading, navigate) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/user/change/admin/password/v1`;
      const { data } = await axios.put(url, formData, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch(refetch(true));
      setLoading(false);
      localStorage.removeItem("vctoken");
      navigate("/login");
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

export const uploadAdminProfileImage =
  (formData, setLoading) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      let userImage;
      const file = formData;
      if (file) {
        const urlPre = `${process.env.REACT_APP_API_BASE_URL}/user/upload/admin/profile/image/v1`;
        const responsePre = await axios.post(
          urlPre,
          {
            sFileName: file.name,
            sContentType: file.type,
          },
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        );

        const { sUrl, sPath } = responsePre.data.data;
        userImage = sPath;

        axios.put(sUrl, file, {
          headers: {
            "Content-Type": file.sContentType,
          },
        });
      }
      const url = `${process.env.REACT_APP_API_BASE_URL}/user/update/profile/v1`;
      const response = await axios.put(
        url,
        { userImage },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch(refetch(true));
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

export default userSlice.reducer;
