import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import userSlice from "./slices/userSlice";
import productSlice from "./slices/productSlice";
import orderSlice from "./slices/orderSlice";

export default configureStore({
  reducer: {
    authSlice,
    userSlice,
    productSlice,
    orderSlice,
  },
});
