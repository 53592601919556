import axios from "axios";

export const uploadImage = async (url, file, setSelectedImage) => {
  try {
    const imgRes = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}${url}`,
      {
        sFileName: file.name,
        sContentType: file.type,
      },
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const surl = imgRes.data.data.sUrl;
    const sImage = imgRes.data.data.sPath;
    await axios.put(surl, file, { headers: { "Content-Type": file.type } });
    setSelectedImage(null);
    return sImage;
  } catch (error) {
    console.error("image upload error", error);
  }
};
