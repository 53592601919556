import React, { useEffect, useMemo, useRef, useState } from "react";
import ViewMoreBtn from "../../components/ViewMoreBtn";
import DataTable from "react-data-table-component";
import { arrowRight } from "../../../../assets/svg/Svg";
import { Funnel, Trash } from "@phosphor-icons/react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../components/DeleteModal";
import { FiEdit } from "react-icons/fi";
import { getAllProducts } from "../../../../redux/slices/productSlice";
import AddProductModal from "../../components/AddProductModal";
import { getAllOrders } from "../../../../redux/slices/orderSlice";
import { useNavigate } from "react-router-dom";

const IMG_URL = process.env.REACT_APP_AWS_IMAGE_URL;

const Order = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [forUpdate, setForUpdate] = useState(false);
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const { orders, refetch } = useSelector((state) => state.orderSlice);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getAllOrders(setIsLoading));
  }, [dispatch]);

  const prevProps = useRef({ refetch }).current;

  useEffect(() => {
    if (prevProps.refetch !== refetch) {
      setIsLoading(true);
      dispatch(getAllOrders(setIsLoading));
    }
    return () => {
      prevProps.refetch = refetch;
    };
  }, [dispatch, prevProps, refetch]);

  useEffect(() => {
    if (orders && orders.length > 0) {
      setData(orders);
    } else {
      setData([]);
    }
  }, [orders]);

  const columns = [
    {
      name: "Order At",
      selector: (row) =>
        new Date(row.createdAt)
          .toLocaleDateString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          })
          .replace(/\//g, "-"),
      sortable: true,
    },
    {
      name: "Order ID",
      selector: (row) => row?._id,
      sortable: true,
    },
    {
      name: "Order By",
      selector: (row) => (
        <div
          style={{ color: "#145f33", fontWeight: "bold" }}
          onClick={() => navigate(`/user/${row?.userDetails?._id}`)}
        >
          {row?.userDetails?.name}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Product Image",
      selector: (row) => (
        <img
          onClick={() => navigate(`/product/${row?.productDetails?._id}`)}
          className="list_img"
          src={`${IMG_URL}${row?.productDetails?.image}`}
          alt="ProductImage"
        />
      ),
      sortable: true,
    },
    {
      name: "Product Title",
      selector: (row) => (
        <div
          style={{ color: "#145f33", fontWeight: "bold" }}
          onClick={() => navigate(`/product/${row?.productDetails?._id}`)}
        >
          {row?.productDetails?.productName}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Product Price",
      selector: (row) => `₹ ${row?.productDetails?.price}`,
      sortable: true,
    },
    {
      name: "Order Status",
      selector: (row) => (
        <label
          className={`badge badge-${
            row.isOrderConfirmed
              ? "success"
              : row.isOrderReject
              ? "danger"
              : "warning"
          }`}
        >
          {row.isOrderConfirmed
            ? "Accepted"
            : row.isOrderReject
            ? "Rejected"
            : "Pending"}
        </label>
      ),
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="d-flex">
          <FiEdit
            size={16}
            onClick={() => {
              setForUpdate(true);
              setId(row._id);
              setModalShow(true);
            }}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Trash
            onClick={() => {
              setDeleteModal(true);
              setId(row._id);
            }}
            size={19}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <ViewMoreBtn redirectUrl={`/order/${row?._id}`} />
        </div>
      ),
    },
  ];

  const filteredData = useMemo(() => {
    return data.filter((item) => {
      const name = item?.userDetails?.name || "";
      const productName = item?.productDetails?.productName || "";
      const searchTextLower = searchText.toLowerCase();

      const matchesSearchText =
        name.toLowerCase().includes(searchTextLower) ||
        productName.toLowerCase().includes(searchTextLower);

      const matchesStatus =
        status === "Active"
          ? item.active === true
          : status === "Inactive"
          ? item.active === false
          : true;

      return matchesSearchText && matchesStatus;
    });
  }, [data, searchText, status]);

  const handleSearch = (value) => {
    setSearchText(value);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const handleStatusChange = (value) => {
    setStatus(value);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    noRowsPerPage: false,
    selectAllRowsItem: false,
    selectAllRowsItemText: "All",
  };

  const customPagination = () => {
    const page = Math.ceil(filteredData.length / paginationPerPage);
    const pageButtons = [];
    const startPage = Math.max(currentPage - 1, 1);
    const endPage = Math.min(currentPage + 1, page);

    // Always add the first page
    pageButtons.push(
      <button
        key={1}
        className={`btn-pagination ${currentPage === 1 ? "active" : ""}`}
        onClick={() => setCurrentPage(1)}
      >
        1
      </button>
    );

    // Ellipsis after the first page if necessary
    if (startPage > 2) {
      pageButtons.push(
        <span key="ellipsis-start" className="pagination-ellipsis">
          &hellip;
        </span>
      );
    }

    // Range of buttons around the current page
    for (let i = startPage; i <= endPage; i++) {
      if (i !== 1 && i !== page) {
        pageButtons.push(
          <button
            key={i}
            className={`btn-pagination ${currentPage === i ? "active" : ""}`}
            onClick={() => setCurrentPage(i)}
          >
            {i}
          </button>
        );
      }
    }

    // Ellipsis before the last page if necessary
    if (endPage < page - 1) {
      pageButtons.push(
        <span key="ellipsis-end" className="pagination-ellipsis">
          &hellip;
        </span>
      );
    }

    // Always add the last page
    if (page !== 1) {
      pageButtons.push(
        <button
          key={page}
          className={`btn-pagination ${currentPage === page ? "active" : ""}`}
          onClick={() => setCurrentPage(page)}
        >
          {page}
        </button>
      );
    }

    return (
      <div className="pagination-container mt-5">
        <button
          className="btn-pagination btn-pagi_prev"
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          {arrowRight}
        </button>
        <span>{pageButtons}</span>
        <button
          className="btn-pagination btn-pagi_next"
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === page}
        >
          {arrowRight}
        </button>
      </div>
    );
  };

  const paginationPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const paginatedData = filteredData.slice(
    (currentPage - 1) * paginationPerPage,
    currentPage * paginationPerPage
  );

  return (
    <>
      {isLoading ? (
        <div className="w-100 d-flex">
          <div
            className="spinner-border text-primary mx-auto"
            role="status"
          ></div>
        </div>
      ) : (
        <>
          <div>
            <div className="row">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <>
                      <DataTable
                        title={<h6 className="card-title">Order List</h6>}
                        columns={columns}
                        data={paginatedData}
                        pagination
                        paginationServer
                        paginationTotalRows={filteredData.length}
                        onChangePage={(page) => setCurrentPage(page)}
                        paginationComponentOptions={paginationOptions}
                        paginationComponent={customPagination}
                        highlightOnHover
                        pointerOnHover
                        subHeader
                        subHeaderAlign="left"
                        subHeaderComponent={
                          <>
                            <Row className="mb-3 w-100">
                              <Col
                                md={8}
                                className="d-flex align-items-start flex-column gap-2"
                              >
                                <span className="d-flex align-items-center gap-2 me-3 filterby_label">
                                  <Funnel />
                                  Filter By :
                                </span>
                                <div className="table_filter">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search by Name or Product"
                                    value={searchText}
                                    onChange={(e) =>
                                      handleSearch(e.target.value)
                                    }
                                  />
                                  <select
                                    className="form-control h-54px w-auto"
                                    value={status}
                                    onChange={(e) =>
                                      handleStatusChange(e.target.value)
                                    }
                                  >
                                    <option selected value="">
                                      Status
                                    </option>
                                    <option value="Active">Active</option>
                                    <option value="Inactive">Inactive</option>
                                  </select>
                                </div>
                              </Col>
                            </Row>
                          </>
                        }
                      />
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AddProductModal
            forUpdate={forUpdate}
            setForUpdate={setForUpdate}
            modalShow={modalShow}
            setModalShow={setModalShow}
            id={id}
            setId={setId}
          />
          <DeleteModal
            forProduct
            deleteModal={deleteModal}
            setDeleteModal={setDeleteModal}
            id={id}
            setId={setId}
          />
        </>
      )}
    </>
  );
};

export default Order;
