import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const NavLink = ({
  commonUrl,
  isPathActive,
  iconClass,
  title,
  url,
  child = false,
}) => {
  const [subdirectory, setSubdirectory] = useState(false);
  const { pathname } = useLocation();

  // Helper function to determine if the path is active
  const isActive = (path) => pathname === path;

  return (
    <>
      <li className={isActive(commonUrl) ? "nav-item active" : "nav-item"}>
        <Link
          className="nav-link"
          to={url}
          onClick={() => setSubdirectory(!subdirectory)}
        >
          <span className="menu-title">{title}</span>
          {iconClass}
        </Link>
      </li>
      {child && subdirectory && (
        <ul className="nav">
          {child.map((c) => {
            return (
              <li
                key={c.url}
                className={isActive(c.url) ? "nav-item active" : "nav-item"}
              >
                <Link className="nav-link py-3" to={c.url}>
                  {c.iconClass}
                  <span className="menu-title">{c.title}</span>
                </Link>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

export default NavLink;
