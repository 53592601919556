import React, { Suspense, lazy, useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import Spinner from "../app/views/shared/Spinner";
import { useDispatch } from "react-redux";
import Product from "./views/pages/product/Product";
import ProductDetails from "./views/pages/product/ProductDetails";
import Order from "./views/pages/order/Order";
import OrderDetails from "./views/pages/order/OrderDetails";

const Dashboard = lazy(() => import("./views/pages/dashboard/Dashboard"));
const Profile = lazy(() => import("./views/pages/Profile"));
const Users = lazy(() => import("./views/pages/user/Users"));
const UserDetail = lazy(() => import("./views/pages/user/UserDetail"));
const Error404 = lazy(() => import("./views/pages/error/Error404"));
const Error500 = lazy(() => import("./views/pages/error/Error500"));
const Auth = lazy(() => import("./views/pages/auth/Auth"));

const SecureRoute = ({ redirectPath = "/login", children }) => {
  if (!localStorage.getItem("token")) {
    return <Navigate to={redirectPath} replace />;
  }
  return children;
};

const AppRoutes = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  // const token = localStorage.getItem("token");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        <Route path="/login" element={<Auth />} />{" "}
        <Route path="/forgot_password" element={<Auth />} />
        <Route path="/reset_password/:resetToken" element={<Auth />} />
        <Route
          path="/change_password"
          element={
            <SecureRoute>
              <Auth />
            </SecureRoute>
          }
        />
        <Route
          path="/"
          element={
            <SecureRoute>
              <Dashboard />
            </SecureRoute>
          }
        />
        <Route
          path="/users"
          element={
            <SecureRoute>
              <Users />
            </SecureRoute>
          }
        />
        <Route
          path="/user/:id"
          element={
            <SecureRoute>
              <UserDetail />
            </SecureRoute>
          }
        />
        <Route
          path="/products"
          element={
            <SecureRoute>
              <Product />
            </SecureRoute>
          }
        />
        <Route
          path="/product/:id"
          element={
            <SecureRoute>
              <ProductDetails />
            </SecureRoute>
          }
        />
        <Route
          path="/orders"
          element={
            <SecureRoute>
              <Order />
            </SecureRoute>
          }
        />
        <Route
          path="/order/:id"
          element={
            <SecureRoute>
              <OrderDetails />
            </SecureRoute>
          }
        />
        <Route
          exact
          path="/profile"
          element={
            <SecureRoute>
              <Profile />
            </SecureRoute>
          }
        />
        <Route path="/404" element={<Error404 />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
