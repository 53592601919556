import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const orderSlice = createSlice({
  name: "order",
  initialState: {
    orders: [],
    order: [],
    refetch: false,
  },
  reducers: {
    getAllOrdersSuccess: (state, action) => {
      state.orders = action.payload;
    },
    getOrderDetailSuccess: (state, action) => {
      state.order = action.payload;
    },
    refetch: (state, action) => {
      state.refetch = action.payload;
    },
  },
});

export const { getAllOrdersSuccess, getOrderDetailSuccess, refetch } =
  orderSlice.actions;

export const getAllOrders = (setIsLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/order/admin/list/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    dispatch(getAllOrdersSuccess(data.data));
    setIsLoading(false);
  } catch (error) {
    console.log(error);
    setIsLoading(false);
  }
};

export const getOrderDetail = (id, setLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/order/admin/details/${id}/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    dispatch(getOrderDetailSuccess(data.data));
    setLoading(false);
  } catch (error) {
    console.log(error);
    setLoading(false);
  }
};

export const acceptOrder =
  (id, deliveryDate, setModalShow, setDeliveryDate, setLoading) =>
  async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/order/accept/${id}/v1`;
      const { data } = await axios.put(
        url,
        { deliveryDate },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch(refetch(true));
      setModalShow(false);
      setDeliveryDate("");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

export const rejectOrder =
  (id, setModalShow, setLoading) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/order/reject/${id}/v1`;
      const { data } = await axios.put(
        url,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch(refetch(true));
      setModalShow(false);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

export const updateDeliveryDate =
  (id, deliveryDate, setModalShow, setDeliveryDate, setLoading) =>
  async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/order/change/delivery/date/${id}/v1`;
      const { data } = await axios.put(
        url,
        { deliveryDate },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch(refetch(true));
      setModalShow(false);
      setDeliveryDate("");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

export const updateDeliveryStatus =
  (id, deliveryStatus, setModalShow, setDeliveryStatus, setLoading) =>
  async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/order/change/delivery/status/${id}/v1`;
      const { data } = await axios.put(
        url,
        { deliveryStatus },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch(refetch(true));
      setModalShow(false);
      setDeliveryStatus("");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

export default orderSlice.reducer;
