import React, { useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { deleteProduct } from "../../../redux/slices/productSlice";

const DeleteModal = ({
  forProduct,
  deleteModal,
  setDeleteModal,
  id,
  setId,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = () => {
    if (forProduct) {
      dispatch(deleteProduct(id, setIsLoading, setDeleteModal));
    }
  };

  return (
    <Modal
      show={deleteModal}
      size="sx"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalBody>
        <h5 className="mb-3">Are you sure you want to Delete ?</h5>
      </ModalBody>
      <Modal.Footer>
        <button
          type="button"
          className="btn_secondary"
          onClick={() => {
            setDeleteModal(false);
            setId("");
          }}
        >
          Cancel
        </button>
        <button type="button" className="btn_primary" onClick={handleDelete}>
          Delete
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
