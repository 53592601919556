import React, { useEffect, useMemo, useRef, useState } from "react";
import ViewMoreBtn from "../../components/ViewMoreBtn";
import DataTable from "react-data-table-component";
import { arrowRight } from "../../../../assets/svg/Svg";
import { Funnel, Trash } from "@phosphor-icons/react";
import { Col, Modal, ModalBody, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { FiEdit } from "react-icons/fi";
import { getAllProducts } from "../../../../redux/slices/productSlice";
import AddProductModal from "../../components/AddProductModal";
import {
  acceptOrder,
  getOrderDetail,
  rejectOrder,
  updateDeliveryDate,
  updateDeliveryStatus,
} from "../../../../redux/slices/orderSlice";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const IMG_URL = process.env.REACT_APP_AWS_IMAGE_URL;
const OrderDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [dateModalShow, setDateModalShow] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [deliveryStatusModalShow, setDeliveryStatusModalShow] = useState(false);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const { order, refetch } = useSelector((state) => state.orderSlice);
  const [deliveryDate, setDeliveryDate] = useState("");
  const [deliveryStatus, setDeliveryStatus] = useState("");

  useEffect(() => {
    setIsLoading(true);
    dispatch(getOrderDetail(id, setIsLoading));
  }, [dispatch]);

  const prevProps = useRef({ refetch }).current;

  useEffect(() => {
    if (prevProps.refetch !== refetch) {
      setIsLoading(true);
      dispatch(getOrderDetail(id, setIsLoading));
    }
    return () => {
      prevProps.refetch = refetch;
    };
  }, [dispatch, prevProps, refetch]);

  useEffect(() => {
    if (order && order.length > 0) {
      setData(order);
      setDeliveryStatus(order?.[0]?.deliveryStatus);
    } else {
      setData([]);
    }
  }, [order]);

  const columns = [
    {
      name: "Order At",
      selector: (row) =>
        new Date(row.createdAt)
          .toLocaleDateString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          })
          .replace(/\//g, "-"),
      sortable: true,
    },
    {
      name: "Order By",
      selector: (row) => (
        <div
          style={{ color: "#145f33", fontWeight: "bold" }}
          onClick={() => navigate(`/user/${row?.userDetails?._id}`)}
        >
          {row?.userDetails?.name}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Product Image",
      selector: (row) => (
        <img
          onClick={() => navigate(`/product/${row?.productDetails?._id}`)}
          className="list_img"
          src={`${IMG_URL}${row?.productDetails?.image}`}
          alt="ProductImage"
        />
      ),
      sortable: true,
    },
    {
      name: "Product Title",
      selector: (row) => (
        <div
          style={{ color: "#145f33", fontWeight: "bold" }}
          onClick={() => navigate(`/product/${row?.productDetails?._id}`)}
        >
          {row?.productDetails?.productName}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Product Price",
      selector: (row) => `₹ ${row?.productDetails?.price}`,
      sortable: true,
    },
    {
      name: "Product Quantity",
      selector: (row) => `${row?.totalQuantity}`,
      sortable: true,
    },
    {
      name: "Total Price",
      selector: (row) => `₹ ${row?.totalPrice}`,
      sortable: true,
    },
    {
      name: "Order Status",
      selector: (row) => (
        <label
          className={`badge badge-${
            row.isOrderConfirmed
              ? "success"
              : row.isOrderReject
              ? "danger"
              : "warning"
          }`}
        >
          {row.isOrderConfirmed
            ? "Accepted"
            : row.isOrderReject
            ? "Rejected"
            : "Pending"}
        </label>
      ),
      sortable: true,
    },
    {
      name: "Delivery Status",
      selector: (row) => (
        <label
          className={`badge badge-${
            row.deliveryStatus === "Delivered" ? "success" : "warning"
          }`}
        >
          {row.deliveryStatus}
        </label>
      ),
      sortable: true,
    },
    {
      name: "Delivery Date",
      selector: (row) => (
        <div className="d-flex">
          {row.deliveryDate || "Order Not Confirmed."}
        </div>
      ),
    },
  ];

  const filteredData = useMemo(() => {
    return data.filter((item) => {
      const name = item?.userDetails?.name || "";
      const productName = item?.productDetails?.productName || "";
      const searchTextLower = searchText.toLowerCase();

      const matchesSearchText =
        name.toLowerCase().includes(searchTextLower) ||
        productName.toLowerCase().includes(searchTextLower);

      const matchesStatus =
        status === "Active"
          ? item.active === true
          : status === "Inactive"
          ? item.active === false
          : true;

      return matchesSearchText && matchesStatus;
    });
  }, [data, searchText, status]);

  const handleSearch = (value) => {
    setSearchText(value);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const handleStatusChange = (value) => {
    setStatus(value);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const handleConfirmOrder = () => {
    const formattedDate = new Date(deliveryDate)
      .toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
      .replace(/\//g, "-");

    console.log(formattedDate, "formattedDate");

    if (formattedDate == "Invalid Date") {
      toast.error("Please Enter Delivery Date");
    } else {
      dispatch(
        acceptOrder(
          id,
          formattedDate,
          setModalShow,
          setDeliveryDate,
          setIsLoading
        )
      );
    }
  };

  const handleReject = () => {
    dispatch(rejectOrder(id, setRejectModal, setIsLoading));
  };

  const handleUpdateDeliveryDate = () => {
    const formattedDate = new Date(deliveryDate)
      .toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
      .replace(/\//g, "-");

    console.log(formattedDate, "formattedDate");

    if (formattedDate == "Invalid Date") {
      toast.error("Please Enter Delivery Date");
    } else {
      dispatch(
        updateDeliveryDate(
          id,
          formattedDate,
          setDateModalShow,
          setDeliveryDate,
          setIsLoading
        )
      );
    }
  };

  const handleUpdateDeliveryStatus = () => {
    console.log(deliveryStatus, "deliveryStatus");
    dispatch(
      updateDeliveryStatus(
        id,
        deliveryStatus,
        setDeliveryStatusModalShow,
        setDeliveryStatus,
        setIsLoading
      )
    );
  };

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    noRowsPerPage: false,
    selectAllRowsItem: false,
    selectAllRowsItemText: "All",
  };

  const customPagination = () => {
    const page = Math.ceil(filteredData.length / paginationPerPage);
    const pageButtons = [];
    const startPage = Math.max(currentPage - 1, 1);
    const endPage = Math.min(currentPage + 1, page);

    // Always add the first page
    pageButtons.push(
      <button
        key={1}
        className={`btn-pagination ${currentPage === 1 ? "active" : ""}`}
        onClick={() => setCurrentPage(1)}
      >
        1
      </button>
    );

    // Ellipsis after the first page if necessary
    if (startPage > 2) {
      pageButtons.push(
        <span key="ellipsis-start" className="pagination-ellipsis">
          &hellip;
        </span>
      );
    }

    // Range of buttons around the current page
    for (let i = startPage; i <= endPage; i++) {
      if (i !== 1 && i !== page) {
        pageButtons.push(
          <button
            key={i}
            className={`btn-pagination ${currentPage === i ? "active" : ""}`}
            onClick={() => setCurrentPage(i)}
          >
            {i}
          </button>
        );
      }
    }

    // Ellipsis before the last page if necessary
    if (endPage < page - 1) {
      pageButtons.push(
        <span key="ellipsis-end" className="pagination-ellipsis">
          &hellip;
        </span>
      );
    }

    // Always add the last page
    if (page !== 1) {
      pageButtons.push(
        <button
          key={page}
          className={`btn-pagination ${currentPage === page ? "active" : ""}`}
          onClick={() => setCurrentPage(page)}
        >
          {page}
        </button>
      );
    }

    return (
      <div className="pagination-container mt-5">
        <button
          className="btn-pagination btn-pagi_prev"
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          {arrowRight}
        </button>
        <span>{pageButtons}</span>
        <button
          className="btn-pagination btn-pagi_next"
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === page}
        >
          {arrowRight}
        </button>
      </div>
    );
  };

  const paginationPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const paginatedData = filteredData.slice(
    (currentPage - 1) * paginationPerPage,
    currentPage * paginationPerPage
  );

  return (
    <>
      {isLoading ? (
        <div className="w-100 d-flex">
          <div
            className="spinner-border text-primary mx-auto"
            role="status"
          ></div>
        </div>
      ) : (
        <>
          <div>
            <div className="row">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <>
                      <DataTable
                        title={
                          <h6 className="card-title">
                            Order Id : {order?.[0]?._id}
                          </h6>
                        }
                        columns={columns}
                        data={paginatedData}
                        paginationServer
                        paginationTotalRows={filteredData.length}
                        onChangePage={(page) => setCurrentPage(page)}
                        paginationComponentOptions={paginationOptions}
                        paginationComponent={customPagination}
                        highlightOnHover
                        pointerOnHover
                        subHeader
                        subHeaderAlign="left"
                        subHeaderComponent={
                          <>
                            <Row className="mb-3 w-100 justify-content-end">
                              <Col md={12} className="d-flex btn-container">
                                {order?.[0]?.isOrderConfirmed &&
                                  !order?.[0]?.isOrderReject && (
                                    <button
                                      className="btn_primary_outline_sm me-2"
                                      style={{ whiteSpace: "nowrap" }}
                                      disabled
                                    >
                                      Accepted
                                    </button>
                                  )}
                                {order?.[0]?.isOrderReject &&
                                  !order?.[0]?.isOrderConfirmed && (
                                    <button
                                      className="btn_primary_outline_sm me-2"
                                      style={{ whiteSpace: "nowrap" }}
                                      disabled
                                    >
                                      Rejected
                                    </button>
                                  )}
                                {!order?.[0]?.isOrderConfirmed &&
                                  !order?.[0]?.isOrderReject && (
                                    <>
                                      <button
                                        className="btn_primary_outline_sm me-2"
                                        style={{ whiteSpace: "nowrap" }}
                                        onClick={() => setModalShow(true)}
                                      >
                                        Accept Order
                                      </button>
                                      <button
                                        className="btn_primary_outline_sm me-2"
                                        style={{ whiteSpace: "nowrap" }}
                                        onClick={() => setRejectModal(true)}
                                      >
                                        Reject Order
                                      </button>
                                    </>
                                  )}
                                <button
                                  className="btn_primary_outline_sm me-2"
                                  style={{ whiteSpace: "nowrap" }}
                                  onClick={() =>
                                    setDeliveryStatusModalShow(true)
                                  }
                                >
                                  Change Delivery Status
                                </button>
                                <button
                                  className="btn_primary_outline_sm"
                                  style={{ whiteSpace: "nowrap" }}
                                  onClick={() => setDateModalShow(true)}
                                >
                                  Change Delivery Date
                                </button>
                              </Col>
                            </Row>
                          </>
                        }
                      />

                      <div className="mt-4">
                        <h6>Delivery Address</h6>
                        <p>{order?.[0]?.deliveryAddress}</p>
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            show={modalShow}
            size="sx"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <ModalBody>
              <h5 className="mb-3">Confirm Order?</h5>
              <div className="mb-3">
                <label htmlFor="deliveryDate" className="form-label">
                  Enter Delivery Date
                </label>
                <input
                  type="date"
                  id="deliveryDate"
                  className="form-control"
                  value={deliveryDate}
                  onChange={(e) => setDeliveryDate(e.target.value)}
                  required
                  min={new Date().toISOString().split("T")[0]}
                />
              </div>
            </ModalBody>
            <Modal.Footer>
              <button
                type="button"
                className="btn_secondary"
                onClick={() => setModalShow(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn_primary"
                onClick={handleConfirmOrder}
              >
                Confirm
              </button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={dateModalShow}
            size="sx"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <ModalBody>
              <h5 className="mb-3">Change Delivery Date?</h5>
              <div className="mb-3">
                <label htmlFor="deliveryDate" className="form-label">
                  Enter Delivery Date
                </label>
                <input
                  type="date"
                  id="deliveryDate"
                  className="form-control"
                  value={deliveryDate}
                  onChange={(e) => setDeliveryDate(e.target.value)}
                  required
                  min={new Date().toISOString().split("T")[0]}
                />
              </div>
            </ModalBody>
            <Modal.Footer>
              <button
                type="button"
                className="btn_secondary"
                onClick={() => setDateModalShow(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn_primary"
                onClick={handleUpdateDeliveryDate}
              >
                Confirm
              </button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={deliveryStatusModalShow}
            size="sx"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <ModalBody>
              <h5 className="mb-3">Change Delivery Status?</h5>
              <div className="mb-3">
                <label htmlFor="deliveryStatus" className="form-label">
                  Select Delivery Status
                </label>
                <select
                  id="deliveryStatus"
                  className="form-control"
                  value={deliveryStatus}
                  onChange={(e) => setDeliveryStatus(e.target.value)}
                  required
                >
                  <option value="">Select</option>
                  <option value="Pending">Pending</option>
                  <option value="Booked">Booked</option>
                  <option value="Dispatch">Dispatch</option>
                  <option value="OutForDelivery">Out for Delivery</option>
                  <option value="Delivered">Delivered</option>
                </select>
              </div>
            </ModalBody>
            <Modal.Footer>
              <button
                type="button"
                className="btn_secondary"
                onClick={() => setDeliveryStatusModalShow(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn_primary"
                onClick={handleUpdateDeliveryStatus}
              >
                Confirm
              </button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={rejectModal}
            size="sx"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <ModalBody>
              <h5 className="mb-3">Are you sure you want to Reject Order ?</h5>
            </ModalBody>
            <Modal.Footer>
              <button
                type="button"
                className="btn_secondary"
                onClick={() => {
                  setRejectModal(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn_primary"
                onClick={handleReject}
              >
                Confirm
              </button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
};

export default OrderDetails;
